var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-column" },
    [
      _c("div", { staticClass: "green-divider" }),
      _c("h1", [_vm._v("Heading 1")]),
      _c("h2", [_vm._v("Heading 2")]),
      _c("h3", [_vm._v("Heading 3")]),
      _c("h4", [_vm._v("Heading 4")]),
      _c("h5", [_vm._v("Heading 5")]),
      _c("h6", [_vm._v("Heading 6")]),
      _c("div", { staticClass: "green-divider" }),
      _c("router-link", { attrs: { to: "test/forms" } }, [
        _vm._v("Test Forms")
      ]),
      _c("router-link", { attrs: { to: "test/modals" } }, [
        _vm._v("Modal Tests")
      ]),
      _c("button", { on: { click: _vm.testCon } }, [
        _vm._v("Show Connectivity Popup")
      ]),
      _c("button", { on: { click: _vm.hideCon } }, [
        _vm._v("Hide Connectivity Popup")
      ]),
      _vm._m(0),
      _vm._m(1)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "test-div" }, [
      _vm._v(" Test for Green Divider "),
      _c("div", { staticClass: "green-divider" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hint-box" }, [
      _c("h4", [_vm._v("Hint Box")]),
      _c("p", [
        _vm._v(
          "This box contains longform hints for using the system. It can be included alongside form controls to provide information to users on how the system functions and what each button does."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }