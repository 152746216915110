<template>
  <div class="flex-column">
    <div class="green-divider"></div>
    <h1>Heading 1</h1>
    <h2>Heading 2</h2>
    <h3>Heading 3</h3>
    <h4>Heading 4</h4>
    <h5>Heading 5</h5>
    <h6>Heading 6</h6>
    <div class="green-divider"></div>
    <router-link to="test/forms">Test Forms</router-link>
    <router-link to="test/modals">Modal Tests</router-link>
    <button @click="testCon">Show Connectivity Popup</button>
    <button @click="hideCon">Hide Connectivity Popup</button>
    <div class="test-div">
      Test for Green Divider
      <div class="green-divider"></div>
    </div>
    <div class="hint-box">
      <h4>Hint Box</h4>
      <p>This box contains longform hints for using the system. It can be included alongside form controls to provide
      information to users on how the system functions and what each button does.</p>
    </div>
  </div>
</template>

<script>
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
export default {
  name: 'test-forms-view',
  components: {
  },
  created () {
  },
  methods: {
    testCon() {
      ErrorHelper.showDisconnectPopup()
    },
    hideCon() {
      ErrorHelper.hideDisconnectPopup()
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../variables';

.flex-column {
  padding: 5em;
  justify-content: center;
}

.test-div {
  color: white;
  background: $theme-color-background-4;
  width: 200px;
  height: 400px;
}



</style>
